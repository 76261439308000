<template>
  <div class="detail-component">
    <div v-if="screen" class="title">
      <div class="container">
        <h4>{{screen.title.default}}</h4>
      </div>
    </div>
    <section
      v-if="showPlaceholder"
      class="filtered-contents-placeholder container"
      :style="[ payloadDetail ? { 'margin-top' : '8.5rem'} : '']"
    >
      <div v-for="(item, i) in 4" :key="i">
        <categoriesPlaceholder></categoriesPlaceholder>
      </div>
    </section>
    <section
      class="error-message"
      v-else-if="!showPlaceholder && filteredContent.length === 0"
      :style="[ payloadDetail ? { 'margin-top' : '8.5rem'} : '']"
    >
      <p>No Data Found</p>
    </section>
    <section
      v-else
      class="detail-grids"
      :style="[ payloadDetail ? { 'margin-top' : '8.5rem'} : '']"
      :class="filteredContent.length > 8 || checkMargin ? smallMargin : bigMargin "
    >
      <gridCategoryCard
        :contents="filteredContent"
        :objectType="objectType"
        :section="screen ? screen : ''"
        :payload="payloadDetail"
        @decreaseMargin="decreaseMargin"
        @increaseMargin="increaseMargin"
      ></gridCategoryCard>
    </section>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";

export default {
  props: {
    // screen: {
    //   type: Object
    // },
    payload: {
      type: Object
    }
  },
  data() {
    return {
      genreParam: {},
      filter: "",
      genre: "",
      background: "linear-gradient(90deg, #1D1D1D 0%, #1A1A1A 100%)",
      showPlaceholder: true,
      filteredContent: [],
      displayType: "",
      toggleDetail: false,
      detailContent: null,
      bigMargin: "bigMargin",
      smallMargin: "smallMargin",
      checkMargin: false,
      objectType: null,
      screen: null,
      menu: null,
      payloadDetail: null
    };
  },
  watch: {
    $route(val) {
      console.log("val route", val);
    }
  },
  computed: {
    ...mapGetters(["appConfig"]),
    fetchCardType() {
      return {
        cardName: "Slider",
        type: "LANDSCAPE",
        height: "162px",
        playButton: { enablePlay: false }
      };
    }
  },
  created() {
    // this.getAllContents();
    // console.log("route params", this.payload);

    if (this.payload && this.payload.hasOwnProperty("checkObj")) {
      if (this.payload.checkObj === true) {
        // this.checkMargin = false;
        eventBus.$emit("list-genre-contents", this.payload.mainObj);
      }
    } else {
      this.getContentScreen();
    }
  },
  mounted() {
    eventBus.$on("genre-specific-contents", response => {
      this.filteredContent = [...response.data];
      this.showPlaceholder = false;
    });
    eventBus.$on("show-genre-placeholder", () => {
      this.filteredContent = [];
      this.showPlaceholder = false;
    });
    eventBus.$on("send-to-detail", data => {
      this.showPlaceholder = true;
      eventBus.$emit("list-genre-contents", data.mainObj);
    });

    eventBus.$on("get-seeAll-data", response => {
      this.filteredContent = [...response.data];
      this.showPlaceholder = false;
      window.scrollTo(0, 0);
    });
  },
  methods: {
    toggleDetailCard(state) {
      this.toggleDetail = state;
    },
    decreaseMargin() {
      if (this.filteredContent.length < 4) {
        this.checkMargin = true;
      }
    },
    increaseMargin() {
      this.checkMargin = false;
    },

    //To get all category contents.
    getAllContents(screen) {
      this.screen.parameters.pagesize = 30;
      let payload = {
        endpoint: this.screen.endpoint,
        params: this.screen.parameters
      };
      window.scrollTo(0, 0);

      let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
        payload.params.genre = "Kids";
      }

      eventBus.$emit("grid-see-all-data", payload);
    },
    //to get the screens from appconfig object.
    getContentScreen() {
      let category = this.$route.params.category;
      let title = this.$route.params.title;
      let screenIndex = this.appConfig.screens.findIndex(element => {
        if (category === "Home") {
          return element.id === "HOME";
        }
      });

      if (screenIndex > -1) {
        let sectionIndex = this.appConfig.screens[
          screenIndex
        ].sections.findIndex(element => {
          return element.title.default === title;
        });

        if (sectionIndex > -1) {
          this.screen = this.appConfig.screens[screenIndex].sections[
            sectionIndex
          ];
          //get all detail component contents.
          this.getAllContents();
        }
      }

      //getting menu Object by matching category
      let menuIndex = this.appConfig.menu.findIndex(element => {
        if (category === "MOVIE") {
          return element.id === "MOVIES";
        }
      });
      this.menu = this.appConfig.menu[menuIndex];
      let payload = {
        menu: this.menu,
        state: true,
        category: category,
        title: title,
        bgColor: "filter-background-dark"
      };
      if (category === "MOVIE") {
        eventBus.$emit("show-category-filter", payload);
      }
    }
  },
  created() {
    if (this.payload && this.payload.hasOwnProperty("checkObj")) {
      this.payloadDetail = this.payload;
      if (this.payloadDetail.checkObj) {
        eventBus.$emit("list-genre-contents", this.payloadDetail.mainObj);
      }
    } else {
      this.getContentScreen();
    }
  },
  mounted() {
    eventBus.$on("genre-specific-contents", response => {
      this.filteredContent = [...response.data];
      this.showPlaceholder = false;
    });
    eventBus.$on("show-genre-placeholder", () => {
      this.filteredContent = [];
      this.showPlaceholder = false;
    });
    eventBus.$on("send-to-detail", data => {
      this.showPlaceholder = true;
      this.payloadDetail = data;
      eventBus.$emit("list-genre-contents", data.mainObj);
    });

    eventBus.$on("get-seeAll-data", response => {
      this.filteredContent = [...response.data];
      this.showPlaceholder = false;
      window.scrollTo(0, 0);
    });
  },
  components: {
    categoriesPlaceholder: () =>
      import(
        /* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"
      ),
    detailCard: () =>
      import(
        /* webpackChunkName: "categoriesPlaceholder" */ "@/components/Templates/detailCard.vue"
      ),
    gridCategoryCard: () =>
      import(
        /* webpackChunkName: "gridCategoryCard" */ "./gridCategoryCards.vue"
      )
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./detailComponent.scss"
</style>
